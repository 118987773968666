import React from 'react';
import { Link } from 'gatsby';
import Divider from '../components/divider';
import { FaCheck, FaTimes } from '../components/fafont';
import { StaticImage } from 'gatsby-plugin-image';
import michellePng from '../images/michelle.png';

const FreeWebsiteDesign = () => {
	return (
		<main>
			<section className="hero">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-8 offset-2 col-md-5 offset-md-0 order-md-2 mb-5 mb-md-0">
							<StaticImage src="../images/hero-img.png" alt="Hero" className="hero__img" />
						</div>
						<div className="col-12 col-md-7 order-md-1">
							<h1 className="h1--lg u-color-secondary-drk-6">
								<strong>Free Website Design</strong>
							</h1>
							<h3 className="u-color-gray-17 u-weight-reg mt-4 mb-5">
								For a Limited Time have one of our Professional Web Designer<br className="d-none d-lg-block" />
								<span className="d-lg-none">&nbsp;</span>design the Site of your dreams!
							</h3>
							<div className="hero__cta">
								<Link to="/free-promotion/" className="btn btn--lg">
									Get your free design
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="featured">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-10 offset-1 col-md-6 offset-md-0 mb-5 mb-md-0">
							<StaticImage src="../images/why-img.png" alt="Why" className="w-100" />
						</div>
						<div className="col-12 col-md-6">
							<div className="featured__copy">
								<h2 className="h2--lg">Why Hire on Codestaff?</h2>
								<p className="p--hg">
									Code Staff is devoted to helping local companies compete in their local or national
									markets. Building a website that properly displays your brand, image and the
									services you provide in a professional manor has never been this easy. With the
									experience and talent that Code Staff provides you can be sure your website is taken
									care of pride.
								</p>

								<p className="p--hg">
									We are so sure you will love working with us we are offering Free Website Design to
									earn your business.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="comparison">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="headline headline--alt">
								<h2 className="h2--lg">Features and benefits</h2>
								<Divider />
							</div>
							<div className="comparison__table">
								<table>
									<thead>
										<tr>
											<th>&nbsp;</th>
											<th>&nbsp;</th>
											<th className="u-weight-bld u-color-white">Recommended Plan</th>
										</tr>
										<tr>
											<th className="u-color-white">SELLING PLAN FEATURES</th>
											<th className="u-color-white">WEBSITE DESIGN</th>
											<th className="u-color-white">WEBSITE DEVELOPMENT</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Custom Website Design</td>
											<FaCheck />
											<FaCheck />
										</tr>
										<tr>
											<td>Mobile-optimized Responsive Design</td>
											<FaCheck />
											<FaCheck />
										</tr>
										<tr>
											<td>A+ Page Speed Rating on Google</td>
											<FaTimes />
											<FaCheck />
										</tr>
										<tr>
											<td>Single Site Hosting</td>
											<FaTimes />
											<FaCheck />
										</tr>
										<tr>
											<td>Dedicated Project Manager</td>
											<FaTimes />
											<FaCheck />
										</tr>
										<tr>
											<td>Private Code Repository</td>
											<FaTimes />
											<FaCheck />
										</tr>
										<tr>
											<td>Custom Domain</td>
											<FaTimes />
											<FaCheck />
										</tr>
										<tr>
											<td>DNS Setup</td>
											<FaTimes />
											<FaCheck />
										</tr>
										<tr>
											<td>Website Security</td>
											<FaTimes />
											<FaCheck />
										</tr>
										<tr>
											<td>Website Backups</td>
											<FaTimes />
											<FaCheck />
										</tr>
										<tr>
											<td>SSL</td>
											<FaTimes />
											<FaCheck />
										</tr>
										<tr>
											<td>SEO Essentials</td>
											<FaTimes />
											<FaCheck />
										</tr>
										<tr>
											<td>Google Analytics</td>
											<FaTimes />
											<FaCheck />
										</tr>
										<tr className="u-vertical-align-top">
											<td>
												<h3 className="u-color-black-2 u-font-main mb-5">Pricing</h3>
												<p className="u-color-tertiary u-lh-sm">
													<Link to="/contact/">Learn more about pricing ›</Link>
												</p>
											</td>
											<td>
												<h3 className="u-uppercase u-color-black-2 u-font-main">FREE</h3>
												<p className="u-color-gray-18 mb-3">+ other selling fees</p>
												<Link to="/free-promotion/" className="btn btn--sm px-5">
													SELECT
												</Link>
											</td>
											<td>
												<h3 className="u-color-black-2 u-font-main">$99/month</h3>
												<p className="u-color-gray-18 mb-3">+ other selling fees</p>
												<Link to="/free-promotion/" className="btn btn--sm px-5">
													SELECT
												</Link>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="portfolio">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="headline portfolio__headline">
								<h2 className="h2--lg">Check Some Our Awesome Portfolios</h2>
								<StaticImage
									src="../images/divider.png"
									alt="Divider"
									className="headline__divider mt-5"
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-md-4 mb-5 mb-md-0">
							<StaticImage src="../images/portfolio-1.png" alt="Portfolio" className="w-100" />
						</div>
						<div className="col-6 col-md-4 mb-5 mb-md-0">
							<StaticImage src="../images/portfolio-2.png" alt="Portfolio" className="w-100" />
						</div>
						<div className="col-6 offset-3 col-md-4 offset-md-0">
							<StaticImage src="../images/portfolio-3.png" alt="Portfolio" className="w-100" />
						</div>
					</div>
				</div>
			</section>
			<section className="quote">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<blockquote className="quote__inner">
								<div className="quote__img" style={{ backgroundImage: `url(${michellePng})` }}>
									&nbsp;
								</div>
								<div className="quote__copy">
									<h4 className="u-weight-smb mb-2 u-font-main">Testimonial</h4>
									<h1 className="h1--md u-weight-xbld u-color-black-2 u-font-main">
										Check What Our Clients<br className="d-none d-lg-block" />
										<span className="d-lg-none">&nbsp;</span>Say About us
									</h1>
									<div className="quote__copy-main">
										<h4 className="h4--lg u-color-gray-18 quote__copy-text">
											It's hard to make me happy regarding website work and having a redesign
											done, I felt would be a challenge. After redesign, its a more personal
											experience. Would recommend.
										</h4>
										<h2 className="u-color-black-2 u-weight-bld u-font-main">Michelle McMahon</h2>
										<h5 className="quote__copy-subtitle">Luxury Transportation</h5>
									</div>
								</div>
							</blockquote>
						</div>
					</div>
				</div>
			</section>
			<section className="cta cta--alt">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
							<h2 className="h2--lg cta__title u-weight-smb">Start your free trial</h2>
							<h5 className="u-color-white u-weight-reg mt-2">Please complete all fields</h5>
							<form action="#" className="cta__form">
								<input type="text" name="first-name" placeholder="First Name*" required />
								<input type="email" name="email-address" placeholder="Email Address*" required />
								<input type="tel" name="phone-number" placeholder="Phone Number*" required />
								<button type="submit" className="btn">
									Submit
								</button>
							</form>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};

export default FreeWebsiteDesign;
