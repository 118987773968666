import React from 'react'
import Layout from '../components/layout'
import FreeWebsiteDesign from '../components/free-website-design'
import Seo from '../components/seo'

const FreeWebsiteDesignPage = ({ location }) => (
  <Layout location={location}>
    <FreeWebsiteDesign />
  </Layout>
)

export const Head = ({ location }) => {
  const title = 'Free Website Design: Codestaff'
  const description =
    'Get your business website Design for Free from a Code Staff Web Designer.'

  return <Seo type="page" title={title} description={description} />
}

export default FreeWebsiteDesignPage
