import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export const FaCheck = () => (
    <td><i><FontAwesomeIcon style={{ color: '#00a4b4' }} className={'faCheck'} icon={faCheck} /></i></td>
)

export const FaTimes = () => (
    <td><i><FontAwesomeIcon style={{ color: '#aab7b8' }} className={'faTimes'} icon={faTimes} /></i></td>
)
