import React from 'react';
import divider from '../images/divider.png';
import dividerAlt from '../images/divider-alt.png';


const Divider = ({alt}) => {
    const dividerImage = alt? dividerAlt : divider
    return    (
    <img src={dividerImage} alt="Divider" className="headline__divider" />
)
    }

export default Divider

